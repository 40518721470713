import React, {
    ReactElement,
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useDomainContext } from "@hooks/useDomainContext";
import ProxiedNumber from "@components/shared/proxiedNumber";
import { useCallCampaignContext } from "@hooks/useCallCampaignContext";
import { useRouter } from "next/router";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import style from "./style.module.scss";
import { convertToBreakpointVariables } from "@page-builder/utils";
import CloseIcon from "@components/svg/closeIcon";
export default function Header({
    slogan,
    children,
    textColor,
    iconColor,
    backGroundColor,
    isLogoClickable = true,
    emptyIcon,
    boxShadow,
    border,
    phoneNumberColor,
    stickyStyle,
    hideSticky,
    waitUntilValidateRecaptcha,
}: {
    slogan?: string;
    children?: ReactNode;
    textColor?: string;
    iconColor?: string;
    backGroundColor?: string;
    isLogoClickable?: boolean;
    emptyIcon?: JSX.Element;
    boxShadow?: string;
    border?: string;
    phoneNumberColor?: string;
    stickyStyle?: {
        stickTitleColor?: string;
        stickBtnTxtColor?: string;
        stickBtnBgColor?: string;
        stickBtnBorderColor?: string;
        stickyPulseColor?: string;
    };
    hideSticky?: boolean;
    waitUntilValidateRecaptcha?: boolean;
}): ReactElement {
    const { domain, blogPost, routeConfig, showFormModal } = useDomainContext();
    const { showPhoneNumber } = useCallCampaignContext();
    const [headerHeight, setHeaderHeight] = useState<string>("60px");
    const header = useRef<HTMLHeadElement>(null);
    const [bannerActive, setBannerActive] = useState<boolean>(false);

    const bannerTextBlock = domain.textBlocks?.find(
        (textBlock) => textBlock.position === "banner",
    );

    useEffect(() => {
        if (header.current) {
            setHeaderHeight(
                String(header.current.offsetHeight?.toFixed(0)) + "px",
            );
        }
        if (bannerTextBlock) {
            setTimeout(() => {
                setBannerActive(true);
            }, 500);
        }
    }, []);

    const logoPath =
        routeConfig?.config?.settings?.logo ?? domain?.logo?.fullPath;

    let phoneNumberBlock = (
        <div className="slogan items-center flex">
            <ProxiedNumber
                slogan={slogan}
                textColor={textColor}
                iconColor={iconColor ?? textColor}
                emptyIcon={emptyIcon}
                phoneNumberColor={phoneNumberColor}
                stickyStyle={stickyStyle}
                hideSticky={hideSticky || showFormModal}
            />
        </div>
    );

    if (waitUntilValidateRecaptcha) {
        phoneNumberBlock = showPhoneNumber ? (
            <div className="slogan items-center flex">
                <ProxiedNumber
                    slogan={slogan}
                    textColor={textColor}
                    iconColor={iconColor}
                    emptyIcon={emptyIcon}
                    phoneNumberColor={phoneNumberColor}
                    stickyStyle={stickyStyle}
                    hideSticky={hideSticky || showFormModal}
                />
            </div>
        ) : (
            <></>
        );
    }

    const { query } = useRouter();

    const finalUrl = useMemo(() => {
        if (!isLogoClickable) {
            return undefined;
        }

        const withQueryParams = gettingQueriesAsString(query, "start", [
            "genericRoute",
            "keyword",
            "category",
            "formTemplate",
            "tool",
            "slug",
            "data",
            "zc",
            "pii-token",
        ]);
        return `/?${withQueryParams}`;
    }, [isLogoClickable, query]);

    const variables = routeConfig?.config?.header?.variables;

    const styles = {
        backgroundColor: backGroundColor,
        boxShadow: boxShadow,
        borderBottom: border,
        ...(variables ? convertToBreakpointVariables(variables) : {}),
    };

    return (
        <header style={styles} className={style.header} ref={header}>
            <div className="inner py-5 lg:py-4 px-5 flex lg:max-w-7xl mx-auto justify-between lg:px-6 items-center ">
                <div
                    className={`${style.logo}`}
                    style={{
                        backgroundImage: `url(${logoPath ?? ""})`,
                        width: "auto",
                        height: "auto",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    <a href={finalUrl} aria-label={`${domain.name} logo`}>
                        {logoPath ? (
                            <img
                                src={logoPath}
                                className="h-auto w-full"
                                alt={`${domain.name} logo`}
                                style={{ visibility: "hidden" }}
                                rel="preload"
                                onLoad={() => {
                                    setHeaderHeight(
                                        String(
                                            header.current?.offsetHeight?.toFixed(
                                                0,
                                            ),
                                        ) + "px",
                                    );
                                }}
                            />
                        ) : (
                            <span className="children:w-full children:h-auto lg:children:h-10">
                                {children}
                            </span>
                        )}
                    </a>
                </div>
                <div>
                    {blogPost && (
                        <div className="text-xs lg:flex hidden justify-end  text-[#6b6b6b] ">
                            Advertisement
                        </div>
                    )}
                    {phoneNumberBlock}
                </div>
                <style>{`:root {--header-height: ${headerHeight} }`}</style>
            </div>
            {bannerTextBlock && (
                <div
                    className={`${style.banner} ${
                        bannerActive ? style.active : ""
                    }`}
                    style={
                        {
                            "--banner-background-color":
                                domain?.config?.bannerBgColor,
                            "--banner-text-color":
                                domain?.config?.bannerTextColor,
                        } as React.CSSProperties
                    }
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: bannerTextBlock?.text,
                        }}
                        className={style["banner-inner"]}
                    ></div>
                    <button
                        onClick={() => {
                            setBannerActive(false);
                        }}
                        type="button"
                        aria-label="Close"
                        className={style["close-button"]}
                    >
                        <span className="close-icon">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}
        </header>
    );
}
